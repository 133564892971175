import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { BasePageData } from '@finn/ui-cosmic';
import {
  Locale,
  replaceStrKeys,
  useCurrentLocale,
  useSessionInCookie,
} from '@finn/ui-utils';
import { useEffect } from 'react';

import pageDataDe from '~/cosmic-cache/de-DE/retention-home.json';

const pageDataObjectDe = pageDataDe?.objects?.[0];

export const useRetentionHomePageData = () => {
  const { locale } = useCurrentLocale();
  const session = useSessionInCookie();
  const isLoggedIn = session?.user?.email;
  const track = useTrackingStore((state) => state.track);
  const pageData = (locale === Locale.GERMAN_GERMANY
    ? pageDataObjectDe
    : pageDataObjectDe) as unknown as BasePageData; // no english version yet

  const retentionPageData = JSON.parse(
    replaceStrKeys(JSON.stringify(pageData), {
      name: session?.user?.hs_first_name
        ? `, ${session?.user?.hs_first_name}`
        : '',
    })
  );

  useEffect(() => {
    if (isLoggedIn) {
      track(TrackingEventName.LOGGED_IN_HOMEPAGE_VIEWED, {});
    }
  }, [isLoggedIn, track]);

  return { retentionPageData: session ? retentionPageData : null };
};
